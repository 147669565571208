<template>
  <BaseLayout>
    <StaticPages
      :title="title"
      :content="content"
      text="text"
    />
  </BaseLayout>
</template>

<script>
import { staticPagesMixin } from '../../mixins/staticPagesMixin'

export default {
  mixins: [staticPagesMixin],

  created () {
    this.aboutUs()
  },
  methods: {
    aboutUs () {
      this.$store.dispatch('footer/aboutUs').then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style';
</style>
