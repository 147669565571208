<template>
  <div class="enter-phone-number h-400">
    <div class="content white-color-bg p-y-48 p-x-42 radius-14">
      <template>
        <h3 class="bold-font fs-22 black-color-text">
          {{ $t('forms.password') }}
        </h3>
        <h5 class="d-flex align-items-center mt-3 mb-1">
          <span>{{ $t('forms.phone') }}</span>
          <span class="mt-1 mx-1"> {{ userPhone }}</span>
          <router-link
            class="edit"
            :to="{ name: 'enter-phone-number' }"
          >
            {{ $t('forms.edit') }}
          </router-link>
        </h5>
        <form @submit.prevent="login">
          <div class="show-hide-password-container">
            <BaseTextInput
              :value="form.password"
              :placeholder="$t('forms.enterPassword')"
              input-id="password"
              class="m-b-14"
              :type="passFieldType"
              :has-error="errors.has('password')"
              :message="errors.get('password')"
              @reset="errors.clear()"
              @submit="validateData"
              @update-value="getPassword"
            />
            <button
              class="show-password"
              type="button"
              @click.prevent="onSwitchPass"
            >
              <OpenEye v-if="passFieldType == 'text'" />
              <ClosedEye v-else />
            </button>
          </div>

          <div
            class="forgot-password m-b-14"
            @click="reSendCode()"
          >
            {{ $t('forms.forgotPassword') }}
          </div>

          <base-button
            :text="$t('forms.login')"
            bg-color-class="main-color-bg"
            is-medium
          />
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import OpenEye from '../../icons/OpenEye'
import ClosedEye from '../../icons/ClosedEye'

import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
  name: 'EnterPassword',
  components: { ClosedEye, OpenEye },
  data () {
    return {
      errors: [],
      form: new Form({
        password: ''
      }),
      passFieldType: 'password',
      errors: new Errors()
    }
  },

  computed: {
    userPhone () {
      return this.$store.getters['Globals/userPhone']
    }
  },

  methods: {
    onSwitchPass () {
      this.passFieldType =
        this.passFieldType === 'password' ? 'text' : 'password'
    },

    getPassword (val) {
      this.form[val.id] = val.value
    },

    validateData () {
      ;['password'].forEach((el) => {
        if (this.form[el] === '') {
          this.errors.recordField({
            [el]: this.$t('forms.validPassword')
          })
        }
      })
    },

    login () {
      this.validateData()
      if (!this.errors.hasAny()) {
        this.$store
          .dispatch('Globals/login', {
            phone: this.userPhone,
            password: this.form.password,
            app_version: '',
            store: ''
          })
          .then((res) => {})
      }
    },

    reSendCode () {
      if (this.userPhone) {
        this.$store
          .dispatch('Globals/forgotPassword', {
            phone: this.userPhone,
            app_version: ''
          })
          .then(() => {})
      } else {
        toast.error('please enter your number')
        this.$router.push({ name: 'enter-phone-number' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './authStyle';
</style>
