<template>
  <BaseLayout>
    <StaticPages
      :title="title"
      :content="content"
    />
  </BaseLayout>
</template>

<script>
import { staticPagesMixin } from '../../mixins/staticPagesMixin'

export default {
  mixins: [staticPagesMixin],

  created () {
    this.usePolicy()
  },
  methods: {
    usePolicy () {
      this.$store.dispatch('footer/usePolicy').then(() => {})
    }
  }
}
</script>
