<template>
  <BaseLayout>
    <section class="profile-page p-y-40">
      <div class="container">
        <div class="static-box white-color-bg radius-14 p-y-30 p-x-20">
          <div class="col-xs-12">
            <h1 mb-4>
              {{ title }}
            </h1>
            <div
              v-for="item in paymentMethods"
              :key="item.index"
              class="m-3 p-3"
            >
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <img
                    :src="item.icon"
                    alt="icon"
                    class="pay-image"
                  >
                  <h2 class="mx-2">
                    {{ item.payment_name }}
                  </h2>
                </div>
                <div
                  class="description"
                  v-html="item.description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
import StaticPages from '@/components/static/index.vue'
export default {
  name: 'Static',
  components: { StaticPages, BaseLayout },

  computed: {
    title () {
      return this.$store.state.footer.page_title
    },
    paymentMethods () {
      return this.$store.state.footer.paymentMethods
    }
  },

  mounted () {
    this.howToPay()
  },

  methods: {
    howToPay () {
      this.$store.dispatch('footer/howToPay').then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../components/static/static';

.pay-image {
  display: inline-block;
  max-width: 140px;
}
h2 {
  font-weight: 500;
}
.description {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 1.86;
}
</style>
