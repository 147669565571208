import BaseLayout from '../layouts/BaseLayout.vue'
import StaticPages from '../components/static/index.vue'

export const staticPagesMixin = {
  name: 'static',
  components: { StaticPages, BaseLayout },
  computed: {
    title () {
      return this.$store.state.footer.title
    },
    content () {
      return this.$store.state.footer.content
    }
  }
}
