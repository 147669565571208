<template>
  <AuthLayout>
    <EnterPassword />
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import EnterPassword from '../../components/auth/register/enterPassword.vue'

export default {
  components: { AuthLayout, EnterPassword }
}
</script>
