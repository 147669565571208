<template>
  <BaseLayout>
    <StaticPages :title="title" :content="content" text="text"> </StaticPages>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout.vue";
import StaticPages from "@/components/static/index.vue";
export default {
  name: "static",
  components: { StaticPages, BaseLayout },
  computed: {
    title() {
      return this.$store.state.footer.title;
    },
    content() {
      return this.$store.state.footer.text;
    },
  },
  methods: {
    howToBuy() {
      this.$store.dispatch("footer/howToBuy").then(() => {});
    },
  },

  created() {
    this.howToBuy();
  },
};
</script>

<style lang="scss" scoped>
@import "./style";
</style>








