<template>
  <BaseLayout>
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-12 col-lg-11 col-xl-10">
          <div class="m-y-40 radius-24">
            <div class="row">
              <!--Will use later-->
              <!-- <div class="col-12 col-lg-6">
                <div class="login-section p-y-30 p-x-20">
                  <form @submit.prevent="validateData">
                    <h3 class="medium-font black-color-text fs-18 m-b-22">
                      {{ $t('home.contactUs') }}
                    </h3>
                    <BaseTextInput
                      :value="form.name"
                      input-id="name"
                      :label="$t('forms.name')"
                      class="m-b-18"
                      :has-error="errors.has('name')"
                      :message="errors.get('name')"
                      isRadius
                      @reset="errors.clear()"
                      @submit="validateData"
                      @update-value="getLoginData"
                    />

                    <BaseTextInput
                      :value="form.email"
                      input-id="email"
                      :label="$t('forms.email')"
                      class="m-b-18"
                      :has-error="errors.has('email')"
                      :message="errors.get('email')"
                      isRadius
                      @reset="errors.clear()"
                      @submit="validateData"
                      @update-value="getLoginData"
                    />

                    <BaseTextInput
                      :value="form.phone"
                      input-id="phone"
                      :label="$t('forms.phone')"
                      class="m-b-18"
                      :has-error="errors.has('phone')"
                      :message="errors.get('phone')"
                      isRadius
                      @reset="errors.clear()"
                      @submit="validateData"
                      @isNumber="isNumber"
                      @update-value="getLoginData"
                    />

                    <template>
                      <label
                        for="message"
                        class="message regular-font"
                      >{{
                        $t('forms.message')
                      }}</label>
                      <textarea
                        id="message"
                        v-model="form.message"
                        class="medium-font m-t-10 radius-26"
                        name="message"
                        rows="4"
                        cols="50"
                        :has-error="errors.has('message')"
                        :message="errors.get('message')"
                        @reset="errors.clear()"
                      />
                    </template>

                    <div
                      class="d-flex justify-content-between align-items-center m-b-18"
                    />

                    <div
                      v-if="errorMessage"
                      class="errorMessage"
                    >
                      {{ errorMessage }}
                    </div>

                    <BaseButton
                      color-class="white-color-text"
                      bg-color-class="main-color-bg"
                      :text="$t('home.contactUs')"
                      is-medium
                      @action="contactUs"
                    />
                  </form>
                </div>
              </div> -->

              <div class="col-12">
                <div class="p-y-30 p-x-20">
                  <h3 class="mb-5">
                    {{ $t('forms.contact') }}
                  </h3>

                  <div class="d-flex align-items-center mb-4">
                    <PhoneIcon />
                    <span class="mx-2">16457</span>
                  </div>

                  <div class="d-flex align-items-center mb-4">
                    <TimeIcon />
                    <span class="mx-2">{{ $t('forms.date') }}</span>
                  </div>

                  <div class="d-flex align-items-center mb-4">
                    <HomeIcon />
                    <span class="mx-2"> {{ $t('forms.address') }}</span>
                  </div>

                  <div class="d-flex align-items-center">
                    <MailIcon />
                    <span class="mx-2"> support@waffarha.com</span>
                  </div>

                  <h3 class="mt-5">
                    {{ $t('forms.social') }}
                  </h3>

                  <div class="d-flex align-items-center my-4">
                    <a
                      href="https://www.facebook.com/Waffarha"
                      class="link"
                      target="_blanck"
                    >
                      <img
                        src="../../assets/images/home/facebook-square.png"
                        alt="facebook"
                      >
                    </a>

                    <a
                      href="https://www.messenger.com/t/Waffarha"
                      class="link mx-2"
                      target="_blanck"
                    >
                      <img
                        src="../../assets/images/home/facebook-messanger.png"
                        alt="facebook"
                      >
                    </a>

                    <a
                      href="mailto:support@waffarha.com"
                      class="link"
                      target="_blanck"
                    >
                      <img
                        src="../../assets/images/home/mail.png"
                        alt="facebook"
                      >
                    </a>

                    <a
                      href="tel:16457"
                      class="link mx-2"
                      target="_blanck"
                    >
                      <img
                        src="../../assets/images/home/call.png"
                        alt="facebook"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
import PhoneIcon from '../../components/icons/PhoneIcon.vue'
import TimeIcon from '../../components/icons/TimeIcon.vue'
import HomeIcon from '../../components/icons/HomeIcon.vue'
import MailIcon from '../../components/icons/MailIcon.vue'
import Form from '@/services/Form'
import Errors from '@/services/Errors'
export default {
  name: 'Contactus',
  components: { BaseLayout, PhoneIcon, TimeIcon, HomeIcon, MailIcon },
  data () {
    return {
      form: new Form({
        name: '',
        email: '',
        phone: '',
        message: ''
      }),
      errors: new Errors(),
      errorMessage: ''
    }
  },
  methods: {
    isNumber (event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault()
    },

    contactUs () {
      delete this.form.originalData
      this.$store
        .dispatch('footer/contactUs', {
          token: this.getUserToken,
          name: this.form.phone,
          email: this.form.email,
          mobile: this.form.phone,
          message: this.form.message,
          contact_type: 'contact'
        })
        .then(() => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  font-size: 14px;
  font-weight: 600;
}

#message {
  height: 45px;
  display: block;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid rgba(154, 162, 181, 0.5);
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}

.link {
  img {
    width: 34px;
    height: 34px;
  }
}
</style>
