<template>
  <section class="profile-page p-y-40">
    <div class="container">
      <div class="static-box white-color-bg radius-14 p-y-30 p-x-20">
        <div class="col-xs-12">
          <h1>{{ title }}</h1>
          <div class="content m-3 p-3">
            <div v-html="content" :class="text"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StaticPages",

  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    item: {
      type: String,
      required: false,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "./static";
</style>
